import { Component, Input, Self, OnInit, Optional } from '@angular/core';
import { Validators, ControlValueAccessor, NgControl, ValidatorFn } from '@angular/forms';
import { FieldErrorMessageComponent } from '../field-error-message/field-error-message.component';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss'],
})
export class FieldInputComponent implements ControlValueAccessor, OnInit {
  @Input() type: 'text' | 'number' | 'email' | 'password' = 'text';
  @Input() mask: 'phone' | 'zip-code' | 'svnr';
  @Input() variant: '' | 'large' = '';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() validationIcon;
  @Input() icon: string;
  @Input() required = false;
  @Input() uppercase = false;
  @Input() noMargin = false;
  @Input() showValidationErrorMessage = true;

  value: string;
  isDisabled = false;

  onChange: (_: any) => void = () => {};
  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(@Self() @Optional() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    const { control } = this.ngControl;

    let validators = this.getValidators();
    validators = control.validator ? [control.validator, ...validators] : this.getValidators();

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  // FORM CONTROL FUNCTIONS
  setValue($event: any) {
    this.value = this.uppercase ? $event.detail.value.toUpperCase() : $event.detail.value;
    this.updateChanges();
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = this.uppercase ? value.toUpperCase() : value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  // PRIVATE
  private getValidators(): ValidatorFn[] {
    const validators = [];

    if (this.type === 'email') {
      validators.push(Validators.email);
    }

    return validators;
  }
}
