import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionRepository } from '../../state/session/session.repository';
import { SyncRepository } from '../../state/sync/sync.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public sessionRepository: SessionRepository,
    private syncRepository: SyncRepository
  ) {}

  canActivate(): boolean {
    return this.isLoggedIn();
  }

  isLoggedIn(): boolean {
    if (!this.sessionRepository.isLoggedIn()) {
      this.syncRepository.setAirplaneModeEnabled(false); // make sure we don't lock the user out of the app
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
