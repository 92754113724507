import { InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';

/*
  Documentation: https://docs.rollbar.com/docs/angular
 */

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar({
    accessToken: environment.log.accessTokenPublic,
    environment: environment.environment,
    captureIp: 'anonymize',
  });
}
