import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { StoreManagementService } from '../../../core/state/store-management/store-management.service';
import { StaffProfile } from './profile.interface';

interface ProfileStore {
  profile: StaffProfile;
}

const initialState = {
  profile: null,
};

const store = createStore({ name: 'profile' }, withProps<ProfileStore>(initialState));

export let persist = persistState(store, {
  key: 'profile',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class ProfileRepository {
  profile$ = store.pipe(select((state) => state.profile));

  constructor(private storeManagementService: StoreManagementService) {
    this.storeManagementService.addToPersistList('profile');
  }

  getStaffId() {
    return store.getValue().profile.staff_id;
  }

  getLanguage() {
    return store.getValue().profile?.language?.locale || 'en';
  }

  resetStore() {
    store.update(() => initialState);
  }

  update(data: Partial<ProfileStore>) {
    store.update((state) => ({ ...state, ...data }));
  }
}
