import { DeviceBiomarker } from '../../../modules/checkup/state/lab-device.interface';
import {
  IntegrationAuthResponse,
  IntegrationDeviceResultResponse,
  IntegrationStatusResponse,
} from './integration.interface';

let deviceStatusCounter = 0;

export const PostAuthMock: IntegrationAuthResponse = {
  data: {
    Authorization:
      'eyJhbGciOiJIUzI1NiIsImV4cCI6MTY2ODgxOTI1NSwiaXNzIjoiaHR0cHM6Ly9sYWIuYXBpLndlbGxhYmUuZml0IiwianRpIjoiVU9cdTAwZWNZXHUwMGY4YTRTXHUwMGUwXHUwMGQ5MzBLNFx1MDBlM1x1MDBkMTRcdTAwZjhcdTAwYmFcdTAwZDBcdTAwZjZcdTAwZjBtXHUwMGMxXHUwMGJlY0JcdTAwYWFMXHUwMGUyelx1MDBhYSIsInR5cCI6IkpXVCJ9.eyJhdXRoX2lkIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJbVY0Y0NJNk1UWTJPRGd4T1RJMU5Td2lhWE56SWpvaWFIUjBjSE02THk5c1lXSXVZWEJwTG5kbGJHeGhZbVV1Wm1sMElpd2lhblJwSWpvaVhIVXdNR1JtWEhVd01HSTFURVl6VWtoTVhIVXdNR1kxUkZ4MU1EQmxZVngxTURCa05seDFNREJqWlZOY2RUQXdaV05aWEhVd01HWTJYSFV3TUdNMWIxeDFNREJqTm1saVhIVXdNR014WmxCY2RUQXdabUZjZFRBd1pUQTRYSFV3TUdVMlhIVXdNR0kxWEhVd01HWmpYSFV3TUdNMklpd2lkSGx3SWpvaVNsZFVJbjAuZXlKaGRYUm9YMmxrSWpvaVRtOXVaV0poWW5saVlXVnlNalExSW4wLjZPM09ubk84U2dJX1lJWUs4azFLaUE1Zlg2REJabnZTMlhLYzVOVGM5eU0ifQ.sEQkhnXQKef0k_hGfkEZrnZRHlHKgpculN-7GhafotU',
  },
  code: 200,
};

export const GetPatientStartMock = {
  data: {
    results: [],
    objects: [],
  },
  code: 200,
};

export const PostPatientDataMock = {
  data: {
    status: 'PATIENT_STATUS_CONFIGURED',
  },
  code: 200,
};

export const GetDeviceStartMock: IntegrationStatusResponse = {
  data: {
    device: 'tanita-mc-780',
    status: 'DEVICE_STATUS_READY',
  },
  code: 200,
};

export const GetDeviceStopMock: IntegrationStatusResponse = {
  data: {
    device: 'tanita-mc-780',
    status: 'DEVICE_STATUS_DISCONNECT',
  },
  code: 200,
};

/**
 * Returns a random status for a specific device.
 * The chance of returning a Ready status depends on the deviceStatusCounter reaching 2
 * which takes from 2 to 3 seconds
 * @param deviceHandle
 * @returns
 */
export const GetDeviceStatusMockFactory = (deviceHandle: string) => {
  const mock: IntegrationStatusResponse = {
    data: {
      device: deviceHandle,
      status: 'DEVICE_STATUS_IN_PROGRESS',
    },
    code: 200,
  };

  if (deviceStatusCounter < 2) {
    deviceStatusCounter++;
  }

  if (deviceStatusCounter === 2) {
    mock.data.status = 'DEVICE_STATUS_RESULTS_READY';
    deviceStatusCounter = 0;
  }

  return mock;
};

export const GetDeviceResultsMockFactory = (
  checkup_id,
  biomarkers: DeviceBiomarker[],
  deviceHandle: string
): IntegrationDeviceResultResponse => {
  const mockData = biomarkers.map((biomarker) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { min_value, max_value } = biomarker;
    const value = Math.floor(Math.random() * (max_value - min_value + 1)) + min_value;
    return {
      checkup_id,
      value,
      biomarker_id: biomarker.biomarker_id,
      device: deviceHandle,
      updated_at: new Date().toISOString(),
    };
  });

  // TODO: return a full patient data if we add result ownership check
  return {
    data: mockData,
    code: 200,
  };
};
