import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, fromEvent, interval, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface ConnectionStatus {
  isConnected: boolean;
  serverError: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  // emits whenever there is a change on the connection status (offline -> online)
  onConnectionChange$: BehaviorSubject<ConnectionStatus>;

  // emits whenever there is a confirmation on the connections status (every request success)
  onConnectionCheck$: BehaviorSubject<ConnectionStatus>;

  private isConnected = navigator.onLine;
  private serverError = null;

  // avoid making parallel requests to the API
  private checking = false;

  // status check interval
  private statusInterval = environment.api.statusInterval;

  // service subscriptions that needs to be removed after service is destroyed
  private subscriptions: Subscription[] = [];

  constructor(private http: HttpClient) {
    this.onConnectionChange$ = new BehaviorSubject({
      isConnected: this.isConnected,
      serverError: this.serverError,
    });
    this.onConnectionCheck$ = new BehaviorSubject({
      isConnected: this.isConnected,
      serverError: this.serverError,
    });

    const statusSub = interval(this.statusInterval).subscribe(() => {
      this.checkApiStatus();
    });

    // We are not using 'online' event as it fires too early and the first request always fail
    // TODO: Test this event when focusing on another window (minizming)
    const offlineSub = fromEvent(window, 'offline').subscribe(() => {
      this.setConnectionStatus(false, this.serverError);
    });

    this.subscriptions.push(statusSub, offlineSub);
  }

  checkApiStatus() {
    if (this.checking) {
      return;
    }

    this.checking = true;
    this.http
      .get(`${environment.api.endpoint}/status`)
      .pipe(
        finalize(() => {
          this.checking = false;
        })
      )
      .subscribe({
        next: () => {
          this.setConnectionStatus(true);
        },
        error: (err) => {
          if (err.message === 'HTTP_ERRORS.AIRPLANE_MODE_ERROR') {
            return;
          }

          // As we are not sure what error the API will throw,
          // we just check if the error wasn't due no internet connection
          // This message is added on the api.interceptor.ts
          let serverError = false;
          if (err.message !== 'HTTP_ERRORS.NO_INTERNET_CONNECTION') {
            serverError = true;
          }
          this.setConnectionStatus(false, serverError);
        },
      });
  }

  /**
   * Updates the connection status and only emits on a status Change
   * @param newStatus true when there is internet, false otherwise
   * @returns
   */
  setConnectionStatus(newStatus: boolean, serverError = false) {
    const status: ConnectionStatus = { isConnected: newStatus, serverError };
    if (this.isConnected !== newStatus) {
      this.onConnectionChange$.next(status);
    }

    this.isConnected = newStatus;

    this.onConnectionCheck$.next(status);
  }

  isOnline() {
    return this.isConnected;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }
}
