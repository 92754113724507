<button
  class="c-btn"
  [ngClass]="'c-btn--' + color"
  [class.c-btn--size-fill]="size === 'fill'"
  [class.c-btn--w-full]="size === 'w-full'"
  [class.c-btn--lg]="size === 'lg'"
  [class.c-btn--lg2]="size === 'lg2'"
  [class.c-btn--sm]="size === 'sm'"
  [class.c-btn--md]="size === 'md'"
  [class.c-btn--fill]="variant === 'fill'"
  [class.c-btn--outline]="variant === 'outline'"
  [disabled]="disabled"
  [type]="type">
  <ion-icon *ngIf="icon" class="c-btn-icon" [name]="icon"></ion-icon>
  <span
    class="c-btn__text"
    [class.u-text-transform--uppercase]="!noUppercase"
    [class.u-text-transform--none]="noUppercase">
    <ng-content></ng-content
  ></span>
</button>
