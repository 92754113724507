import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IntegrationRepository } from '../../state/integration/integration.repository';
import { SyncRepository } from '../../state/sync/sync.repository';

@Injectable({
  providedIn: 'root',
})
export class AirplaneModeInterceptor implements HttpInterceptor {
  constructor(private syncRepository: SyncRepository, private integrationRepository: IntegrationRepository) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isIntegrationRequest = request.url.includes(this.integrationRepository.endpoint());
    const airplaneModeStatus = this.syncRepository.getAirplaneModeEnabled();

    if (!isIntegrationRequest && airplaneModeStatus) {
      return throwError(() => ({
        message: 'HTTP_ERRORS.AIRPLANE_MODE_ERROR',
        original: new HttpErrorResponse({ status: 0, url: request.url }),
      }));
    }

    return next.handle(request);
  }
}
