export enum CheckupSectionState {
  Introduction = 'introduction',
  Waiting = 'waiting',
  Inputs = 'inputs',
}

export enum LabDeviceStatus {
  loading = 'loading',
  neutral = 'neutral',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export interface DeviceBiomarker extends Biomarker {
  value: number | string;
}

export interface LabDeviceWithReadingTab {
  order: number;
  name: string;
  lab_tab_id: number;
  biomarkers: DeviceBiomarker[];
}
export interface LabDevice {
  manufacturer?: string;
  model: string;
  handle: string;
  manual_measurement: boolean;
  duration: number;
  attention: boolean;
  order: number;
  lab_name: string;
  lab_device_id: number | string;
  tabs: DeviceTab[];

  // internal flags
  status?: LabDeviceStatus;
  loading?: boolean;
}

export interface Biomarker {
  min_value: number;
  max_value: number;
  lab_decimals: number;
  lab_name: string;
  biomarker_id: number | string;
}

export interface DeviceTab {
  order: number;
  name: string;
  lab_tab_id: number;
  biomarkers: Biomarker[];
}

export interface LabDeviceWithReading extends LabDevice {
  selected: boolean;
  status: LabDeviceStatus;
  state: CheckupSectionState;
  timer: string;
  duration: number;
  tabs: LabDeviceWithReadingTab[];
}
