import { throwError, Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { environment } from '../../../../environments/environment';

import { LoggerService } from '../../services/logger-service/logger.service';
import { SessionRepository } from '../../state/session/session.repository';
import { IntegrationRepository } from '../../state/integration/integration.repository';
import { Router } from '@angular/router';
import { SessionService } from '../../state/session/session.service';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private loggerService: LoggerService,
    private sessionRepository: SessionRepository,
    private integrationRepository: IntegrationRepository,
    private router: Router,
    private sessionService: SessionService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // As this intercepts every request, we need to modify the headers depending
    // if it is an integration or backend request
    const isIntegrationRequest = request.url.includes(this.integrationRepository.endpoint());
    const requestTimeoutLimit = isIntegrationRequest
      ? environment.lab_integration.maxWaiting
      : environment.api.maxWaiting;

    const isLoggedUser = isIntegrationRequest
      ? this.integrationRepository.isAuthenticated()
      : this.sessionRepository.isLoggedIn();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { access_token, token_type } = this.sessionRepository.session();
    const token = this.integrationRepository.session();

    let requestModified = request;

    if (isLoggedUser) {
      requestModified = isIntegrationRequest
        ? this.addIntegrationAuthHeader(request, token)
        : this.addSessionAuthHeader(request, token_type, access_token);
    }

    return next.handle(requestModified).pipe(
      timeout(requestTimeoutLimit),
      catchError((err) => {
        const isLoginRequest = request.url.includes(`login`);
        const isUnauthenticated = err?.status === 401;

        if (!isLoginRequest && isUnauthenticated) {
          this.sessionService.appLogout();
        }

        const shouldLogError = !isIntegrationRequest;

        if (shouldLogError) {
          this.loggerService.error(err);
        }

        return this.customErrorHandler(err);
      })
    );
  }

  private addSessionAuthHeader(
    request: HttpRequest<any>,
    tokenType: string,
    accessToken: string
  ): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(environment.api.authHeader, `${tokenType} ${accessToken}`),
    });
  }

  private addIntegrationAuthHeader(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set(environment.lab_integration.authHeader, `${accessToken}`),
    });
  }

  private customErrorHandler(error: any) {
    let errorMessage: string;

    if (error === 'ERROR_NO_INTERNET' || error === 'ERR_INTERNET_DISCONNECTED' || error.name === 'TimeoutError') {
      errorMessage = 'HTTP_ERRORS.NO_INTERNET_CONNECTION';
    } else {
      errorMessage =
        error && error.error && error.error.message ? error.error.message : 'HTTP_ERRORS.DEFAULT_MESSAGE';
    }

    return throwError(() => ({ message: errorMessage, original: error }));
  }
}
