import { Injectable } from '@angular/core';
import { PackagesRepository } from '../../../modules/packages/state/packages.repository';
import { SessionRepository } from '../session/session.repository';
import { StoreManagementRepository } from './store-management.repository';

@Injectable({
  providedIn: 'root',
})
export class StoreManagementService {
  constructor(
    private storeManagementRepository: StoreManagementRepository,
    private sessionRepository: SessionRepository,
    private packagesRepository: PackagesRepository
  ) {}

  addToPersistList(storeName) {
    this.storeManagementRepository.updatePersistList(storeName, 'add');
  }

  removeFromPersistList(storeName) {
    this.storeManagementRepository.updatePersistList(storeName, 'remove');
  }

  resetRepositories() {
    this.storeManagementRepository.resetRepositories();
    this.sessionRepository.resetSession();
  }
}
