import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  Observable,
  of,
  retry,
  throwError,
  TimeoutError,
} from 'rxjs';
import { ProfileRepository } from '../../../modules/profile/state/profile.repository';
import { ConnectionStatus, NetworkService } from '../../services/network/network.service';
import { TranslationsService } from '../../services/translations-service/translations.service';
import { ToastService } from '../../toasts/services/toast-service/toast.service';
import { SessionRepository } from '../session/session.repository';
import { SyncConnectionStatus, RequestType, Sync } from './sync.interface';
import { SyncRepository } from './sync.repository';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  // TODO: add support for retries, so we avoid getting stuck in one request;
  // TODO: make sure send results & reset password only after checkup was performed;

  // General Status for the Sync service, to be used on the Sync Dialog
  readonly status$ = new BehaviorSubject<SyncConnectionStatus>(SyncConnectionStatus.OK);

  // syncing status, prevents syncing more than one item at a time
  private syncing = false;

  // safety-flag to avoid initializing this service more than once
  private initialized = false;

  constructor(
    private syncRepository: SyncRepository,
    private http: HttpClient,
    private network: NetworkService,
    private toastService: ToastService,
    private translationService: TranslationsService,
    private sessionRepository: SessionRepository,
    private profileRepository: ProfileRepository
  ) {}

  init() {
    if (this.initialized) {
      return;
    }

    this.synchronizeWhenAvailable();

    this.handleStatusChange();

    this.initialized = true;
  }

  post(url: string, checkupId: string | number, wellabeId: string, label: string, payload = null) {
    return this.http.post(url, payload).pipe(
      catchError((err) => {
        return this.handleError(err, url, checkupId, wellabeId, label, 'post', payload);
      })
    );
  }

  put(url: string, checkupId: string | number, wellabeId: string, label: string, payload = null) {
    return this.http.put(url, payload).pipe(
      catchError((err) => {
        return this.handleError(err, url, checkupId, wellabeId, label, 'put', payload);
      })
    );
  }

  /**
   * Checks if there's any request being synced that matches the checkupId.
   * Valid only while syncing.
   * @param checkupId
   * @returns boolean
   */
  isCheckupSyncInProgress(checkupId: string | number): boolean {
    const status = this.status$.getValue();

    if (status !== SyncConnectionStatus.SYNCING) {
      return false;
    }

    const entities = this.syncRepository.getSyncItems();
    const isAnyCheckupItem = !!entities.find((sync) => sync.checkupId === checkupId);

    return isAnyCheckupItem;
  }

  /**
   * Listens to network changes and pending Sync items and synchronize them when possible.
   * Note that listening to 'syncRepository.count$; allow us to automatically synchronize when
   * an item is deleted or added to the queue
   */
  private synchronizeWhenAvailable() {
    combineLatest([this.network.onConnectionCheck$, this.syncRepository.count$])
      .pipe(filter(([connectionStatus, count]) => connectionStatus.isConnected && count > 0))
      .subscribe(() => {
        if (this.sessionRepository.isLoggedIn()) {
          const staffId = this.profileRepository.getStaffId();
          const syncItem = this.syncRepository.getNextSyncItem(staffId);
          this.synchronize(syncItem);
        }
      });
  }

  /**
   * Update the service status depending on network conditions and pending items
   */
  private handleStatusChange() {
    combineLatest([
      this.network.onConnectionChange$,
      this.syncRepository.count$,
      this.syncRepository.isAirplaneModeEnabled$,
    ]).subscribe(([connectionStatus, count, isAirplaneModeEnabled]) => {
      this.updateStatus(connectionStatus, count, isAirplaneModeEnabled);
    });
  }

  private handleError(
    err: { message: string; original: HttpErrorResponse },
    url: string,
    checkupId: string | number,
    wellabeId: string,
    label: string,
    requestType: RequestType,
    payload = null
  ) {
    if (this.shouldSyncLater(err)) {
      const staffId = this.profileRepository.getStaffId();
      const sync: Sync = {
        url,
        payload,
        checkupId,
        wellabeId,
        requestType,
        staffId,
        label,
        status: 'pending',
      };

      this.syncRepository.addSync(sync);

      const phrase = this.translationService.getInstantTranslation('FORM_SUBMIT_MESSAGES.BIOMARKER_GROUP_CACHED');
      this.toastService.warning(phrase, { unique: true });

      // return of(), converting an error into a success and falling into the happy path
      // returns payload as this is normally what the API sends back to us
      return of(payload);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      return throwError(() => new Error(err.message));
    }
  }

  /**
   * Syncs if:
   * - Request timeout
   * - No internet (status 0);
   * - Internal server error (status 5xx)
   * @param err HTTP Error
   * @returns
   */
  private shouldSyncLater(err: { message: string; original: HttpErrorResponse }) {
    const { status } = err?.original;
    return (
      err.original instanceof TimeoutError ||
      status === 0 ||
      (status >= 500 && status < 600) ||
      !this.network.isOnline()
    );
  }

  private synchronize(syncItem: Sync) {
    // returns if an item is already being synced;
    if (this.syncing || !syncItem) {
      return;
    }

    let reqObs$: Observable<any> = null;

    // Re-creates the request depending on the requestType
    const { url, payload } = syncItem;
    switch (syncItem.requestType) {
      case 'post':
        reqObs$ = this.http.post(url, payload);
        break;
      case 'put':
        reqObs$ = this.http.put(url, payload);
        break;
    }

    if (!reqObs$) {
      return;
    }

    this.syncing = true;

    this.syncRepository.updateEntityStatus(syncItem.url, 'loading');

    reqObs$.pipe(retry(3)).subscribe({
      next: () => {
        // removes entry from the repository
        // this also triggers the sync$ to change, therefore calling syncronize in case there are pending sync items
        this.syncRepository.deleteSync(syncItem.url);

        // syncing flag must be set to false inside subscribe, an not on finalize pipe
        // finalize pipe is called after the 'next' is completed, setting it to false after the `deleteSync`
        // syncing flag also mut be set after the item is deleted, otherwise there's a race condition
        // for the store status update on the next items
        this.syncing = false;
      },
      error: (err) => {
        this.syncing = false;
        const { status } = err.original;
        // In case there is an issue with this request, mark it as error to show on the sync dialog
        if (status >= 400 && status < 600) {
          this.syncRepository.updateEntityStatus(syncItem.url, 'error');
        } else {
          this.syncRepository.updateEntityStatus(syncItem.url, 'pending');
        }
      },
    });
  }

  private updateStatus(
    connectionStatus: ConnectionStatus,
    numPendingItems: number,
    isAirplaneModeEnabled: boolean
  ) {
    if (isAirplaneModeEnabled) {
      this.status$.next(SyncConnectionStatus.AIRPLANE);
      return;
    }

    /* Error handling */
    if (!connectionStatus.isConnected) {
      const hasServerError = connectionStatus.serverError;
      const errorStatus = hasServerError ? SyncConnectionStatus.ERROR : SyncConnectionStatus.OFFLINE;
      this.status$.next(errorStatus);
      return;
    }

    const isPending = numPendingItems !== 0;
    const successStatus = isPending ? SyncConnectionStatus.SYNCING : SyncConnectionStatus.OK;
    this.status$.next(successStatus);
  }
}
