import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Ethnicity, Language, PhoneCountryCodeResponse } from './metadata.interface';
import { MetadataRepository } from './metadata.repository';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  constructor(private http: HttpClient, private metadataRepository: MetadataRepository) {}

  defaultLanguage() {
    const deviceLocale = navigator.language;
    const deviceLanguage = deviceLocale.substring(0, 2);

    // TODO: after capacitor installation, remove lines above and uncomment lines below
    // refactor on translations service will be needed to match the async / await promise
    // const deviceLocale = await Device.getLanguageCode();
    // const deviceLanguage = deviceLocale.value.substring(0, 2);

    const availableLanguages = environment.availableLanguages;
    const defaultLanguage = environment.defaultLanguage;

    let language: string;

    if (availableLanguages.includes(deviceLanguage)) {
      language = deviceLanguage;
    } else if (deviceLanguage === 'pt' && !environment.production) {
      language = 'en';
    } else {
      language = defaultLanguage;
    }

    this.metadataRepository.update({ defaultLanguage: language });

    return language;
  }

  getLanguages() {
    return this.http.get<Language[]>(`${environment.api.endpoint}/metadata/languages`).pipe(
      tap((languages) => {
        this.metadataRepository.addLanguages(languages);
      }),
      catchError((err) => this.handleAirplaneMode(err))
    );
  }

  getPhoneCountryCodes() {
    return this.http
      .get<PhoneCountryCodeResponse>(`${environment.api.endpoint}/metadata/phone_country_codes`)
      .pipe(
        tap((codes) => {
          const phoneCodes = Object.keys(codes);

          const entities = phoneCodes.map((code) => ({
            id: code,
            name: codes[code].name,
            code: codes[code].code,
          }));

          this.metadataRepository.addPhoneCodes(entities);
        }),
        catchError((err) => this.handleAirplaneMode(err))
      );
  }

  getSexPhrase(value: number) {
    switch (value) {
      case 1:
        return 'FORM_GENDER_OPTIONS.MALE';
      case 2:
        return 'FORM_GENDER_OPTIONS.FEMALE';
      default:
        return 'FORM_GENDER_OPTIONS.NOT_KNOWN';
    }
  }

  getTimezones() {
    return this.http.get<string[]>(`${environment.api.endpoint}/metadata/timezones`).pipe(
      tap((timezones) => {
        this.metadataRepository.update({ timezones });
      }),
      catchError((err) => this.handleAirplaneMode(err))
    );
  }

  getEthnicities() {
    return this.http.get<Ethnicity[]>(`${environment.api.endpoint}/metadata/ethnicities`).pipe(
      tap((ethnicities) => {
        this.metadataRepository.update({ ethnicities });
      }),
      catchError((err) => this.handleAirplaneMode(err))
    );
  }

  /**
   * Temporary solution for uncaught errors on Airplane mode
   * This shouldn't be an issue on production, as all the requests made in this service
   * should be done before the user even has the chance of activating the airplane mode
   * Suggestion for improvement: store the default values for these requests as JSONs
   * @param err typed any as its used
   */
  private handleAirplaneMode(err: any) {
    if (err.message !== 'HTTP_ERRORS.AIRPLANE_MODE_ERROR') {
      return throwError(() => err);
    }

    return of(null);
  }
}
