import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MetadataService } from '../../state/metadata/metadata.service';
import { MetadataRepository } from '../../state/metadata/metadata.repository';
import { ProfileRepository } from '../../../modules/profile/state/profile.repository';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  constructor(
    private translateService: TranslateService,
    private metadataService: MetadataService,
    private metadataRepository: MetadataRepository,
    private profileRepository: ProfileRepository
  ) {
    this.addLangs(environment.availableLanguages);
    this.setDefaultLang(this.metadataService.defaultLanguage());
    this.setLanguage(this.profileRepository.getLanguage());
    const locale = this.metadataRepository.getStoredLanguage();
    const phrases = this.metadataRepository.getPhrases();
    this.translateService.setTranslation(locale, phrases);
  }

  public init() {}

  public setDefaultLang(language: string) {
    this.translateService.setDefaultLang(language);
  }

  public addLangs(languagesList: string[]) {
    this.translateService.addLangs(languagesList);
  }

  public listenLanguageChangeEvent(): Observable<any> {
    return this.translateService.onLangChange;
  }

  public getInstantTranslation(key: string, interpolateParams = null): string {
    return this.translateService.instant(key, interpolateParams);
  }

  public setLanguage(lang: string) {
    this.translateService.use(lang);
  }

  public setLangById(langId: number) {
    const availableLangs = this.metadataRepository.getLanguages();
    const lang = availableLangs[langId];
    if (lang) {
      this.setLanguage(lang.locale);
    }
  }

  public getLangs() {
    return this.translateService.getLangs();
  }
}
