import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { getEntity, selectEntity, updateEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { sessionStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { CheckupReading, CheckupResponse, Observation, UserdataBody } from './checkups.interface';

export interface CheckupState extends CheckupResponse {
  observation: Observation;
}

const store = createStore(
  { name: 'checkup' },
  withEntities<CheckupState, 'checkup_id'>({ idKey: 'checkup_id', initialValue: [] })
);

export let persist;

@Injectable({ providedIn: 'root' })
export class CheckupsRepository {
  constructor() {
    persist = persistState(store, {
      key: 'checkup',
      storage: sessionStorageStrategy,
    });
  }

  addCheckup(checkup: CheckupResponse) {
    const observation = this.getObservation(checkup.checkup_id);

    return store.update(upsertEntities({ ...checkup, observation }));
  }

  addObservation(observation: Observation, checkupId: number | string) {
    const checkup = this.getCheckup(checkupId);

    if (!checkup) {
      return;
    }

    return store.update(updateEntities(checkupId, { observation, ...checkup }));
  }

  /**
   * Upsert checkup reading values into existing checkup on repository
   * @param checkupId
   * @param newReadings
   */
  upsertCheckupReadings(checkupId: string | number, newReadings: CheckupReading[]) {
    const checkup = store.query(getEntity(checkupId));
    if (!checkup || !newReadings || newReadings.length <= 0) {
      return;
    }

    const updatedReadings = checkup.readings || [];

    // Upsert new readings into existing checkup readings
    newReadings.forEach((newReading) => {
      const readingIndex = updatedReadings.findIndex(
        (checkupReading) => checkupReading.biomarker_id === newReading.biomarker_id
      );
      if (readingIndex > -1) {
        updatedReadings[readingIndex] = newReading;
      } else {
        updatedReadings.push(newReading);
      }
    });

    store.update(updateEntities(checkupId, (entity) => ({ ...entity, readings: updatedReadings })));
  }

  updateCheckupUserdata(
    checkupId: string | number,
    userDataPayload: UserdataBody,
    observationPayload: Partial<Observation>
  ) {
    const checkup = this.getCheckup(checkupId);

    const response: CheckupState = {
      ...checkup,
      has_diabetes: userDataPayload?.has_diabetes,
      has_pacemaker: userDataPayload?.has_pacemaker,
      hours_last_exercise: userDataPayload?.hours_last_exercise,
      hours_last_meal: userDataPayload?.hours_last_meal,
      recent_intense_exercise: userDataPayload?.recent_intense_exercise,
      in_medical_treatment: userDataPayload?.in_medical_treatment,
      smoking_status: userDataPayload?.smoking_status,
      is_pregnant: userDataPayload?.is_pregnant,
      observation: {
        ...checkup.observation,
        quality_related: observationPayload?.quality_related,
        special_case: observationPayload?.special_case,
        text: observationPayload?.text,
      },
      user: {
        ...checkup.user,
        birth_year: userDataPayload?.birth_year,
        has_diabetes: userDataPayload?.has_diabetes,
        has_pacemaker: userDataPayload?.has_pacemaker,
        height: userDataPayload?.height,
        in_medical_treatment: userDataPayload?.in_medical_treatment,
        is_pregnant: userDataPayload?.is_pregnant,
        name: userDataPayload?.name,
        sex: userDataPayload?.sex,
      },
    };

    store.update(updateEntities(checkupId, response));
  }

  updateEthnicityId(checkupId: string | number, ethnicity_id: number) {
    const checkup = this.getCheckup(checkupId);

    if (!checkup) {
      return;
    }

    store.update(updateEntities(checkupId, (state) => ({ ...state, user: { ...state.user, ethnicity_id } })));
  }

  updateTara(checkupId: string | number, tara: number) {
    const checkup = this.getCheckup(checkupId);

    if (!checkup) {
      return;
    }

    store.update(updateEntities(checkupId, (state) => ({ ...state, tara })));
  }

  getCheckup(checkupId: string | number): CheckupState {
    return store.query(getEntity(checkupId));
  }

  getObservation(checkupId: string | number): Observation {
    return store.query(getEntity(checkupId))?.observation;
  }

  getEthnicityById(checkupId: string | number) {
    return store.query(getEntity(checkupId))?.user?.ethnicity_id;
  }

  getTaraById(checkupId: string | number) {
    return store.query(getEntity(checkupId))?.tara;
  }

  selectCheckup(checkupId: string | number) {
    return store.pipe(selectEntity(checkupId));
  }
}
