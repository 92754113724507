import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CheckupReading, UserdataBody } from '../../../modules/checkup/state/checkups.interface';
import { LabDeviceRepository } from '../../../modules/checkup/state/lab-device.repository';
import { PackagesRepository } from '../../../modules/packages/state/packages.repository';
import { EndpointConfigurationModal } from '../../../shared/modals/endpoint-configuration/endpoint-configuration.modal';
import { TranslationsService } from '../../services/translations-service/translations.service';
import { ToastService } from '../../toasts/services/toast-service/toast.service';
import { StoreManagementService } from '../store-management/store-management.service';
import {
  IntegrationAuthResponse,
  IntegrationDeviceResultResponse,
  IntegrationDeviceStatus,
  IntegrationError,
  IntegrationPostPatientResponse,
  IntegrationStatusResponse,
} from './integration.interface';
import {
  GetDeviceResultsMockFactory,
  GetDeviceStartMock,
  GetDeviceStatusMockFactory,
  GetDeviceStopMock,
  GetPatientStartMock,
  PostAuthMock,
  PostPatientDataMock,
} from './integration.mock';
import { IntegrationRepository } from './integration.repository';
import { CheckupsRepository } from '../../../modules/checkup/state/checkups.repository';

@Injectable({ providedIn: 'root' })
export class IntegrationService {
  constructor(
    private http: HttpClient,
    private integrationRepository: IntegrationRepository,
    private storeManagementService: StoreManagementService,
    private toastService: ToastService,
    private translationService: TranslationsService,
    private packageRepository: PackagesRepository,
    private devicesRepository: LabDeviceRepository,
    private modalController: ModalController,
    private checkupRepository: CheckupsRepository
  ) {}

  postIntegrationLogin(credentials) {
    credentials = {
      ...credentials,
      client_id: environment.api?.clientId,
    };
    return this.http
      .post<IntegrationAuthResponse>(`${this.integrationRepository.endpoint()}/auth`, credentials)
      .pipe(
        catchError((err) => {
          return this.handleIntegrationError(err, PostAuthMock);
        }),
        tap((session: IntegrationAuthResponse) => {
          if (!session) {
            return;
          }
          this.integrationRepository.update(session.data.Authorization);
        })
      );
  }

  reset() {
    this.storeManagementService.resetRepositories();
    this.integrationRepository.resetSession();
  }

  getPatientStart(checkupId: number) {
    return this.http.get<any>(`${this.integrationRepository.endpoint()}/patient/${checkupId}`).pipe(
      catchError((err) => {
        return this.handleIntegrationError(err, GetPatientStartMock);
      })
    );
  }

  postPatientData(body: UserdataBody) {
    const activePackage = this.packageRepository.getActivePackage();
    const activeCheckup = this.checkupRepository.getCheckup(body.checkup_id);

    const ethinicityValue = body.ethnicity_id ? body.ethnicity_id : 1; // default value

    const requestBody = {
      ...body,
      package_token: activePackage?.voucher,
      ethnicity_id: ethinicityValue,
      package_id: activePackage?.package_id,
      client_id: activePackage?.client?.client_id,
      provider_id: activePackage?.client?.provider?.provider_id,
      user_id: activeCheckup?.user?.user_id,
    };

    return this.http
      .post<IntegrationPostPatientResponse>(`${this.integrationRepository.endpoint()}/patient`, requestBody)
      .pipe(
        catchError((err) => {
          return this.handleIntegrationError(err, PostPatientDataMock);
        })
      );
  }

  startDevice(checkupId: number | string, deviceHandle: string) {
    return this.http
      .get<IntegrationStatusResponse>(
        `${this.integrationRepository.endpoint()}/device/${checkupId}/${deviceHandle}/run`
      )
      .pipe(catchError((err) => this.handleIntegrationError(err, GetDeviceStartMock)));
  }

  stopDevice(checkupId: number | string, deviceHandle: string): Observable<IntegrationStatusResponse> {
    return this.http
      .get<IntegrationStatusResponse>(
        `${this.integrationRepository.endpoint()}/device/${checkupId}/${deviceHandle}/stop`
      )
      .pipe(
        catchError((err) => {
          return this.handleIntegrationError(err, GetDeviceStopMock);
        })
      );
  }

  getDeviceStatus(checkupId: number | string, deviceHandle: string) {
    return this.http
      .get<IntegrationStatusResponse>(
        `${this.integrationRepository.endpoint()}/device/${checkupId}/${deviceHandle}/status`
      )
      .pipe(
        catchError((err) => {
          return this.handleIntegrationError(err, GetDeviceStatusMockFactory(deviceHandle));
        }),
        map((response: IntegrationStatusResponse) => {
          if (!response) {
            return IntegrationDeviceStatus.Waiting;
          }
          // TODO: check with Ervin if this makes sense
          switch (response.data?.status) {
            case 'DEVICE_STATUS_RESULTS_READY':
              return IntegrationDeviceStatus.Ready;
            case 'DEVICE_STATUS_CONFIGURING':
            case 'DEVICE_STATUS_CONFIGURED':
            case 'DEVICE_STATUS_PENDING':
            case 'DEVICE_STATUS_IN_PROGRESS':
            case 'DEVICE_STATUS_READY':
              return IntegrationDeviceStatus.Waiting;
            default:
              return IntegrationDeviceStatus.Error;
          }
        })
      );
  }

  getDeviceResults(checkupId, deviceHandle): Observable<CheckupReading[]> {
    return this.http
      .get<IntegrationDeviceResultResponse>(
        `${this.integrationRepository.endpoint()}/result/${checkupId}/${deviceHandle}`
      )
      .pipe(
        catchError((err) => {
          // use device's biomarker to build a fake dataset
          const biomarkers = this.devicesRepository.getAllBiomarkers(deviceHandle);

          // use the fake dataset to return mock data
          return this.handleIntegrationError(
            err,
            GetDeviceResultsMockFactory(checkupId, biomarkers, deviceHandle)
          );
        }),
        map((response: IntegrationDeviceResultResponse) => {
          return response.data.map((reading) => {
            return { biomarker_id: reading.biomarker_id, value: reading.value };
          });
        })
      );
  }

  async displayEndpointConfigurationModal(): Promise<void> {
    const currentConfiguration = this.integrationRepository.getEndpointConfig();

    const modal = await this.modalController.create({
      mode: 'ios',
      component: EndpointConfigurationModal,
      componentProps: {
        startingConfiguration: currentConfiguration,
      },
      cssClass: 'c-ion-modal',
    });

    await modal.present();
  }

  /**
   *
   * @param err API error from the Lab Integration API
   * @param mockData Mock data that should be injected in the case simulated is set to true
   * @returns Observable or Error Observable
   */
  private handleIntegrationError(err, mockData: any) {
    // Parses the error message to show something actionable
    // This is important if the integration server is down
    let friendlyError = this.parseError(err);

    // In case the integration is down, check the simulated/production settings
    // If the server is running we ignore the simulated flag
    if (this.isIntegrationDown(err)) {
      if (environment.lab_integration.simulated) {
        return of(mockData);
      }

      if (!environment.production) {
        this.toastService.error(friendlyError, { unique: true });

        // returns an 'of' to bypass login if it is not production
        return of(null);
      }
    }

    // Omit this specific error: https://app.asana.com/0/1182800557000793/1205867556098167
    if (err.original?.error?.code == 1006) {
      friendlyError = null;
    }

    // Otherwise just throws an error
    err.message = friendlyError;
    return throwError(() => new Error(err.message));
  }

  private parseError(err) {
    if (err.original && err.original.status === 0) {
      return this.translationService.getInstantTranslation('INTEGRATION.SERVER_NOT_AVAILABLE');
    }

    /*
      Parses the LMS response to show a custom error message
    */
    if (err.original && err.original.status !== 200) {
      const errorData: IntegrationError = err.original.error;

      if (errorData) {
        const message = this.translationService.getInstantTranslation('INTEGRATION.ERROR', {
          code: errorData.code,
          message: errorData.data.details,
        });

        return message;
      }
    }

    return err.message;
  }

  private isIntegrationDown(err) {
    return err.original && err.original.status === 0;
  }
}
