import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentDatesRepository } from '../../../modules/appointment-dates/state/appointment-dates.repository';

@Injectable({
  providedIn: 'root',
})
export class ActiveDateGuard {
  constructor(public router: Router, private appointmentDatesRepository: AppointmentDatesRepository) {}

  canActivate() {
    return this.hasActiveDate();
  }

  hasActiveDate() {
    if (Boolean(this.appointmentDatesRepository.getActiveDateId())) {
      return true;
    }

    this.router.navigate(['/appointment-dates']);
    return false;
  }
}
