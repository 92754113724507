import { Injectable } from '@angular/core';
import { createStore, getRegistry } from '@ngneat/elf';
import { deleteEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

const store = createStore({ name: 'storeManagement' }, withEntities<{ id: string }>());

export let persist = persistState(store, {
  key: 'storeManagement',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class StoreManagementRepository {
  constructor() {
    this.updatePersistList('storeManagement', 'add');
  }

  updatePersistList(storeName: string, action: 'add' | 'remove') {
    getRegistry().forEach((storage) => {
      if (storage.name !== storeName) {
        return;
      }

      if (action === 'add') {
        store.update(upsertEntities({ id: storeName }));
      }

      if (action === 'remove') {
        store.update(deleteEntities(storeName));
      }
    });
  }

  resetRepositories() {
    const persistList = store.getValue().ids;

    getRegistry().forEach((storage) => {
      if (!persistList.includes(storage.name)) {
        storage.reset();
      }
    });
  }
}
