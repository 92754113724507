import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() color: 'primary' | 'secondary' | 'danger' | 'inactive' | 'neutral' | 'gray' = 'primary';
  @Input() size: 'lg' | 'lg2' | 'sm' | 'md' | 'w-full' | 'fill' | '' = '';
  @Input() variant: 'fill' | 'outline' | 'clear' = 'fill';

  @Input() disabled: boolean;
  @Input() noUppercase: boolean = false;
  @Input() icon: string;
  @Input() type: string = 'button';

  ngOnInit() {}
}
