import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { catchError, of, tap } from 'rxjs';
import { MetadataRepository, skipWhileMetadataCached } from '../../state/metadata/metadata.repository';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslationsLoaderService implements TranslateLoader {
  constructor(private http: HttpClient, private metadataRepository: MetadataRepository) {}

  public getTranslation(lang: string) {
    const contentHeader = new HttpHeaders({
      'Accept-Language': lang,
    });

    const storedPhrases = this.metadataRepository.getPhrases();

    return this.http
      .get<{ [key: string]: string }>(`${environment.api.endpoint}/metadata/phrases/labapp`, {
        headers: contentHeader,
      })
      .pipe(
        tap((phrases) => {
          this.metadataRepository.updatePhrases({ language: { phrases, locale: lang } });
        }),
        catchError(() => {
          let phrases;

          if (storedPhrases) {
            phrases = of(storedPhrases);
          } else {
            phrases = this.http.get(`./assets/i18n/${lang}.json`);
          }

          return phrases;
        })
      );
  }
}
