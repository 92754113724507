import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { StoreManagementService } from '../store-management/store-management.service';
import { entitiesPropsFactory, selectAllEntities, setEntities } from '@ngneat/elf-entities';
import { Ethnicity, Language, PhoneCountryCode } from './metadata.interface';
import { withRequestsCache, createRequestsCacheOperator, updateRequestCache } from '@ngneat/elf-requests';

interface Metadata {
  defaultLanguage?: string;
  language: { phrases: { [key: string]: string }; locale: string };
  timezones: string[];
  ethnicities?: Ethnicity[];
}

const initialState = {
  language: { phrases: null, locale: null },
  timezones: null,
};

const { languagesEntitiesRef, withLanguagesEntities } = entitiesPropsFactory('languages');
const { phoneCodesEntitiesRef, withPhoneCodesEntities } = entitiesPropsFactory('phoneCodes');

const languageId: any = 'language_id';

const store = createStore(
  { name: 'metadata' },
  withProps<Metadata>(initialState),
  withLanguagesEntities<Language>({ idKey: languageId }),
  withPhoneCodesEntities<PhoneCountryCode>(),
  withRequestsCache<'phrases'>()
);

export const persist = persistState(store, {
  key: 'metadata',
  storage: localStorageStrategy,
});

export const skipWhileMetadataCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class MetadataRepository {
  languages$ = store.pipe(selectAllEntities({ ref: languagesEntitiesRef }));
  phoneCodes$ = store.pipe(selectAllEntities({ ref: phoneCodesEntitiesRef }));
  timezones$ = store.pipe(select((state) => state.timezones));

  constructor(private storeManagementService: StoreManagementService) {
    this.storeManagementService.addToPersistList('metadata');
  }

  addLanguages(languages: Language[]) {
    store.update(setEntities(languages, { ref: languagesEntitiesRef }));
  }

  addPhoneCodes(codes: PhoneCountryCode[]) {
    store.update(setEntities(codes, { ref: phoneCodesEntitiesRef }));
  }

  getDefaultLanguage() {
    return store.getValue()?.defaultLanguage;
  }

  getPhrases() {
    return store.getValue()?.language?.phrases;
  }

  getStoredLanguage() {
    return store.getValue()?.language?.locale;
  }

  getEthnicities() {
    return store.getValue()?.ethnicities;
  }

  getLanguages() {
    return store.getValue().languagesEntities;
  }

  update(data: Partial<Metadata>) {
    store.update((state) => ({ ...state, ...data }));
  }

  updatePhrases(data: Partial<Metadata>) {
    // 1 minute of ttl
    store.update(updateRequestCache('phrases', { ttl: 60000 }), (state) => ({ ...state, ...data }));
  }
}
