import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IntegrationService } from '../../state/integration/integration.service';

/**
 * Service for communication between the renderer proccess (the LabApp application)
 * and the main process (Electron)
 *
 * For now, we just use it for triggering configuration of the integration endpoint
 * Check src/preload.ts for possible event channels
 */
@Injectable({
  providedIn: 'root',
})
export class ElectronService {
  private hasInitialized = false;

  constructor(private integrationService: IntegrationService) {}

  init() {
    if (this.hasInitialized) {
      return;
    }

    if (this.isRunningElectron()) {
      this.registerEventListeners();
    }

    this.hasInitialized = true;
  }

  private registerEventListeners() {
    try {
      window.api.on('openPortConfiguration', () => {
        this.integrationService.displayEndpointConfigurationModal();
      });
    } catch (err) {
      console.error('Error when registering Electron event listeners', err);
    }
  }

  private isRunningElectron(): boolean {
    const platform = Capacitor.getPlatform();
    return platform === 'electron';
  }
}
