import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  addEntities,
  getActiveEntity,
  getEntitiesCount,
  getEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectManyByPredicate,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { environment } from '../../../../environments/environment';
import { Appointment, Package, PackageDateType, Station } from './packages.interface';

const store = createStore(
  { name: 'packages' },
  withEntities<Package, 'package_id'>({ idKey: 'package_id' }),
  withActiveId()
);

export let persist;

@Injectable({ providedIn: 'root' })
export class PackagesRepository {
  packages$ = store.pipe(selectAllEntities());

  activePackage$ = store.pipe(selectActiveEntity());

  constructor() {
    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'packages',
      storage: strategy,
    });
  }

  addPackages(packages: Package[]) {
    store.update(addEntities(packages));
  }

  filterPackagesByDate(date: PackageDateType) {
    if (date === 'all') {
      return store.pipe(selectAllEntities());
    }

    return store.pipe(selectManyByPredicate((entity) => entity.when === date));
  }

  getActivePackage() {
    return store.query(getActiveEntity());
  }

  getActivePackageId() {
    return store.value.activeId;
  }

  getPackageById(id: number) {
    return store.query(getEntity(id));
  }

  getPackagesCount() {
    return store.query(getEntitiesCount());
  }

  setActivePackage(id: number) {
    store.update(setActiveId(id));
  }

  searchPackages(term) {
    // convert to case insensitive
    const insensitiveTerm = new RegExp(term, 'i');

    return store.pipe(
      selectManyByPredicate(
        (entity) =>
          entity.package_id == term ||
          insensitiveTerm.test(entity.voucher) ||
          insensitiveTerm.test(entity.client?.name)
      )
    );
  }

  setPackages(packages: Package[]) {
    store.update(setEntities(packages));
  }

  resetActivePackage() {
    store.update(resetActiveId());
  }

  upsertPackage(packageItem: Package) {
    const storedPackage = store.query(getEntity(packageItem.package_id));
    const updatedPackage = {
      ...storedPackage,
      ...packageItem,
      client: {
        ...storedPackage?.client,
        ...packageItem?.client,
      },
    };

    store.update(upsertEntities(updatedPackage));
  }

  updateUserPresence(appointmentId: number, status: boolean) {
    const activePackage = this.getActivePackage();
    const appointments = [...activePackage.appointments];

    const index = appointments.findIndex((appointment) => appointment.appointment_id === appointmentId);

    appointments[index].no_show = status;

    store.update(updateEntities(activePackage.package_id, (entity) => ({ ...entity, appointments })));
  }

  updateAppointmentStatus(checkupId: number | string, status: string) {
    const activePackage = this.getActivePackage();
    const { appointments } = activePackage;

    const index = appointments.findIndex((appointment) => +appointment?.checkup?.checkup_id === +checkupId);

    if (index < 0) {
      return;
    }

    appointments[index].checkup.lab_status = status;

    store.update(updateEntities(activePackage?.package_id, (entity) => ({ ...entity, appointments })));
  }

  setPackageStations(packageId: number, stations: Station[]) {
    store.update(
      updateEntities(packageId, (entity) => ({
        ...entity,
        stations,
      }))
    );
  }

  setPackageAppointments(packageId: number, appointments: Appointment[]) {
    store.update(
      updateEntities(packageId, (entity) => ({
        ...entity,
        appointments,
      }))
    );
  }
}
