import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslationsService } from '../../../core/services/translations-service/translations.service';

/** @ignore */
@Component({
  selector: 'app-field-error-message',
  templateUrl: './field-error-message.component.html',
  styleUrls: ['./field-error-message.component.scss'],
})
export class FieldErrorMessageComponent implements AfterViewInit, OnDestroy {
  @Input() visible = true;
  @Input() currentControl: UntypedFormControl;

  errorMessage = null;

  private controlSubscription;

  constructor(private translationsService: TranslationsService) {}

  ngAfterViewInit() {
    this.controlSubscription = this.currentControl.valueChanges.subscribe(() => {
      this.getErrorMessage();
    });
  }

  ngOnDestroy() {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  private getErrorMessage() {
    this.errorMessage = null;

    if (this.currentControl.errors) {
      Object.keys(this.currentControl.errors).forEach((key) => {
        this.errorMessage = this.getValidatorErrorMessage(key);
      });
    }
  }

  private getValidatorErrorMessage(validatorName: string) {
    switch (validatorName) {
      case 'email':
        return 'FORM_VALIDATION_MESSAGES.INVALID_EMAIL';

      case 'required':
        return 'FORM_VALIDATION_MESSAGES.FIELD_REQUIRED';

      case 'notSame':
        return 'FORM_VALIDATION_MESSAGES.PASSWORDS_MATCH';

      case 'weakPassword':
        return 'FORM_VALIDATION_MESSAGES.WEAK_PASSWORD';

      case 'minMaxValidator': {
        const { minMaxValidator } = this.currentControl.errors;
        return this.translationsService.getInstantTranslation('FORM_VALIDATION_MESSAGES.FIELD_RANGE', {
          minValue: minMaxValidator.minValue,
          maxValue: minMaxValidator.maxValue,
        });
      }

      default:
        return 'FORM_VALIDATION_MESSAGES.INVALID_INPUT';
    }
  }
}
