import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PackagesRepository } from '../../../modules/packages/state/packages.repository';

@Injectable({
  providedIn: 'root',
})
export class ActivePackageGuard {
  constructor(public router: Router, private packagesRepository: PackagesRepository) {}

  canActivate() {
    return this.hasActivePackage();
  }

  hasActivePackage() {
    if (Boolean(this.packagesRepository.getActivePackageId())) {
      return true;
    }

    this.router.navigate(['/packages']);
    return false;
  }
}
