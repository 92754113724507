export const environment = {
  production: false,
  environment: 'development',
  api: {
    endpoint: 'https://api.wellabe.dev',
    maxWaiting: 10000,
    authHeader: 'Authorization',
    clientId: 3,
    statusInterval: 15000, // interval for status check api call
  },
  lab_integration: {
    endpoint: 'http://localhost:5100',
    authHeader: 'Authorization',
    maxWaiting: 30000,
    simulated: false,
  },
  log: {
    enabled: true,
    payload: {},
    accessTokenPublic: '3e45c5f015b74a779fd562a3ec7de7a5',
  },
  config: {
    generalDeviceHandle: 'wellabe-userdata',
    lastStationDeviceHandle: 'finish-checkup',
    timeBetweenDeviceChecks: 500, //  in milliseconds, should be a integer
    testCheckupId: 'test-checkup',
  },
  availableLanguages: ['en', 'de'],
  defaultLanguage: 'de',
};
