export interface IntegrationError {
  data: {
    key: string;
    message: string;
    details: string;
    url: string;
  };
  code: number;
}

export interface IntegrationAuthResponse {
  code: number;
  data: {
    Authorization: string;
  };
}

export interface IntegrationPostPatientResponse {
  data: { status: string };
  code: number;
}

export interface IntegrationPostPatientBody {
  patientID: number;
  firstname: string;
  lastname: string;
  gender: number;
  ethnicity?: number;
  smoking_status: number;
  height: number;
  activity_level: number;
  package_id: number;
  client_id: number;
  user_id: number;
  package_token: string;
  tara?: number;
  provider_id: string;
}

export interface IntegrationConfig {
  endpoint: string;
}

export interface IntegrationState {
  token: string;
  configuration: IntegrationConfig;
}

export interface IntegrationStatusResponse {
  data: {
    device: string;
    status: string;
  };
  code: number;
}

export enum IntegrationDeviceStatus {
  Ready = 'ready',
  Waiting = 'waiting',
  Error = 'error',
}

export interface IntegrationDeviceResult {
  checkup_id: string;
  biomarker_id: number | string;
  value: number | string;
  device: string;
  updated_at: string;
}

export interface IntegrationDeviceResultResponse {
  data: IntegrationDeviceResult[];
  code: number;
}
