import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StationsRepository } from '../../../modules/stations/state/stations.repository';

@Injectable({
  providedIn: 'root',
})
export class ActivePackageStationGuard {
  constructor(public router: Router, private stationsRepository: StationsRepository) {}

  canActivate() {
    return this.hasActiveStation();
  }

  hasActiveStation() {
    if (Boolean(this.stationsRepository.getActiveStationId())) {
      return true;
    }

    this.router.navigate(['/packages']);
    return false;
  }
}
