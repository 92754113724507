import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs';
import { IntegrationConfig, IntegrationState } from './integration.interface';
import { StoreManagementService } from '../store-management/store-management.service';

const getInitialState = (): IntegrationState => {
  const configObj: IntegrationState = {
    token: null,
    configuration: {
      endpoint: environment.lab_integration.endpoint || 'http://localhost:5100',
    },
  };
  return configObj;
};

const initialState: IntegrationState = getInitialState();

const store = createStore({ name: 'integration' }, withProps<IntegrationState>(initialState));

export let persist;

@Injectable({ providedIn: 'root' })
export class IntegrationRepository {
  isAuthenticated$ = store.pipe(
    select((state) => state.token),
    map((token) => Boolean(token))
  );

  session$ = store.pipe(select((state) => state));

  constructor(private storeManagementService: StoreManagementService) {
    this.storeManagementService.addToPersistList('integration');

    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'integration',
      storage: strategy,
    });
  }

  isAuthenticated(): boolean {
    return Boolean(store.getValue().token);
  }

  session(): string {
    return store.getValue().token;
  }

  update(token: string) {
    store.update((state) => ({ ...state, token }));
  }

  /**
   * If we don't have a port configured, consider it as a web url
   * E.g: https://lab.api.wellabe.dev
   * Append the '/api' suffix to it at the end
   * @returns the integration url ready to be used
   */
  endpoint(): string {
    const { endpoint } = store.getValue().configuration;

    return `${endpoint}/api`;
  }

  getEndpointConfig(): IntegrationConfig {
    const config = store.getValue().configuration;
    return config;
  }

  getDefaultConfig(): IntegrationConfig {
    const config = getInitialState().configuration;
    return config;
  }

  updateEndpointConfig(newConfig: IntegrationConfig) {
    store.update((state) => ({ ...state, configuration: newConfig }));
  }

  /**
   * Reset only the session, we want to keep the server configuration as is
   */
  resetSession() {
    store.update((state) => {
      return { ...state, token: null };
    });
  }
}
