import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  addEntities,
  getActiveEntity,
  getAllEntitiesApply,
  getEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectAllEntitiesApply,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LabDevice } from '../../checkup/state/lab-device.interface';
import { Station } from '../../packages/state/packages.interface';

const store = createStore(
  { name: 'stations' },
  withEntities<Station, 'station_number'>({ initialValue: [], idKey: 'station_number' }),
  withActiveId()
);

export let persist;

@Injectable({ providedIn: 'root' })
export class StationsRepository {
  activeStation$ = store.pipe(selectActiveEntity());
  stations$ = store.pipe(selectAllEntities()).pipe(map((items) => items.sort((value) => value.order)));
  manualStation$ = store.pipe(selectAllEntitiesApply({ filterEntity: (entity) => entity.manual }));

  constructor() {
    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'stations',
      storage: strategy,
    });
  }

  getActiveStation() {
    return store.query(getActiveEntity());
  }

  getActiveStationId() {
    return store.value.activeId;
  }

  getManualStations() {
    return store.query(getAllEntitiesApply({ filterEntity: (entity) => entity.manual }));
  }

  getStationLabDevices(station_number: number) {
    return store.query(getEntity(station_number))?.lab_devices;
  }

  setActiveStation(station_number: number): void {
    store.update(setActiveId(station_number));
  }

  resetActiveStation(): void {
    store.update(resetActiveId());
  }

  setStations(stations: Station[]) {
    const manualStations = this.getManualStations();

    store.update(setEntities([...manualStations, ...stations]));
  }

  setManualStation(station: Station) {
    const payload: Station = { ...station, manual: true };

    store.update(upsertEntities(payload));
  }

  addDevicesToStation(id: number, devices: LabDevice[]) {
    store.update(updateEntities(id, (entity) => ({ ...entity, lab_devices: devices })));
  }

  addManualStation(devices: LabDevice[]) {
    const manualStationMock: Station = {
      lab_devices: devices,
      order: -1,
      station_number: -1,
      manual: true,
    };

    store.update(addEntities(manualStationMock));
  }
}
