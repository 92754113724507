<div
  data-testid="toast"
  class="c-toast"
  [ngClass]="'c-toast--' + toast.type"
  [class.c-toast--hidden]="!toast.visible">
  <div class="c-toast__icon" *ngIf="toast.icon">
    <ion-icon [name]="toast.icon"></ion-icon>
  </div>
  <div class="c-toast__message">
    <span [innerHTML]="toast?.message | translate"></span>
  </div>
  <button class="c-toast__button" *ngIf="toast.button" (click)="handleClick($event)">
    <span>{{ toast.button }}</span>
  </button>
</div>
