export type SyncItemStatus = 'loading' | 'pending' | 'error';

export interface Sync {
  url: string;
  payload: any;
  requestType: RequestType;
  checkupId: number | string;
  wellabeId: string;
  staffId: string | number;
  label: string;
  status: SyncItemStatus;
}

export type RequestType = 'post' | 'get' | 'put';

export enum SyncConnectionStatus {
  OFFLINE = 'OFFLINE',
  SYNCING = 'SYNCING',
  AIRPLANE = 'AIRPLANE',
  ERROR = 'ERROR',
  OK = 'OK',
}
