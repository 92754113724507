<div class="c-field" [class.u-margin-bottom--none]="noMargin" ion-content>
  <h6 class="c-field__label" [class.c-field__label--invalid]="ngControl.invalid && ngControl.touched">
    {{ label }}
  </h6>

  <div class="c-field__input-wrapper">
    <app-field-validation-icon *ngIf="validationIcon" [icon]="validationIcon" [control]="$any(ngControl).control">
    </app-field-validation-icon>

    <ion-input
      mode="md"
      class="c-field__input"
      [email]="type === 'email'"
      [type]="type"
      [placeholder]="placeholder"
      [required]="required"
      [class.c-field__input--invalid]="ngControl.invalid && ngControl.touched"
      [class.c-field__input--disabled]="isDisabled"
      [class.c-field__input--large]="variant === 'large'"
      [class.c-field__input--has-validation-icon]="validationIcon"
      [class.c-field__input--has-icon]="icon"
      [class.c-field__input-uppercase]="uppercase"
      [formControl]="$any(ngControl).control"
      (ionChange)="setValue($event)">
    </ion-input>

    <ion-icon *ngIf="icon" [name]="icon" class="c-field__icon"></ion-icon>
  </div>

  <app-field-error-message [visible]="showValidationErrorMessage" [currentControl]="$any(ngControl).control">
  </app-field-error-message>
</div>
