import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/guards/auth/auth-guard.service';
import { NoAuthGuard } from './core/guards/no-auth/no-auth.service';
import { ActiveDateGuard } from './core/guards/appointment-dates/appointment-dates.guard';
import { ActivePackageGuard } from './core/guards/packages/packages.guard';
import { ActivePackageStationGuard } from './core/guards/packages-stations/packages-stations.guard';

/*
  Please check the article below for understanding how to structure modules
  https://medium.com/swlh/angular-organizing-features-and-modules-e582611a720e
*/

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./modules/session/session.module').then((m) => m.SessionModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'packages',
    loadChildren: () => import('./modules/packages/packages.module').then((m) => m.PackagesModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'stations',
    loadChildren: () => import('./modules/stations/stations.module').then((m) => m.StationsModule),
    canActivate: [AuthGuardService, ActivePackageGuard],
  },
  {
    path: 'appointment-dates',
    loadChildren: () =>
      import('./modules/appointment-dates/appointment-dates.module').then((m) => m.AppointmentDatesModule),
    canActivate: [AuthGuardService, ActivePackageGuard],
  },
  {
    path: 'appointment-list',
    loadChildren: () =>
      import('./modules/appointment-list/appointment-list.module').then((m) => m.AppointmentListModule),
    canActivate: [AuthGuardService, ActivePackageGuard, ActiveDateGuard],
  },
  {
    path: 'checkup',
    loadChildren: () => import('./modules/checkup/checkup.module').then((m) => m.CheckupModule),
    canActivate: [AuthGuardService, ActivePackageGuard, ActivePackageStationGuard],
  },
  {
    path: 'station-config',
    loadChildren: () =>
      import('./modules/station-config/station-config.module').then((m) => m.StationConfigModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'example',
    loadChildren: () => import('./modules/example-feature/example-feature.module').then((m) => m.HomePageModule),
  },
  {
    path: '**',
    redirectTo: 'packages',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
