import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IntegrationConfig } from '../../../core/state/integration/integration.interface';
import { IntegrationRepository } from '../../../core/state/integration/integration.repository';

@Component({
  selector: 'app-endpoint-configuration',
  templateUrl: './endpoint-configuration.modal.html',
  styleUrls: ['./endpoint-configuration.modal.scss'],
})
export class EndpointConfigurationModal implements OnInit {
  @Input() startingConfiguration: IntegrationConfig;

  configurationForm: FormGroup<{ endpoint: FormControl<string> }>;

  constructor(private modalController: ModalController, private integrationRepository: IntegrationRepository) {
    this.configurationForm = this.buildConfigurationForm();
  }

  ngOnInit() {
    this.setValues(this.startingConfiguration);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  setDefaultValues() {
    const defaultValues = this.integrationRepository.getDefaultConfig();
    this.setValues(defaultValues);
  }

  confirmConfiguration(): void {
    const value = { endpoint: this.configurationForm.controls.endpoint.value };
    this.integrationRepository.updateEndpointConfig(value);
    this.dismiss();
  }

  private setValues(values: IntegrationConfig): void {
    this.configurationForm.setValue(values);
  }

  private buildConfigurationForm(): FormGroup {
    const formGroup: FormGroup<{ endpoint: FormControl<string> }> = new FormGroup<{
      endpoint: FormControl<string>;
    }>({
      endpoint: new FormControl(null, [Validators.required]),
    });

    return formGroup;
  }
}
