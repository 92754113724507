import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { Session } from './session.interface';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs';

const initialState: Session = {
  access_token: undefined,
  expires_in: undefined,
  token_type: undefined,
};

const store = createStore({ name: 'session' }, withProps<Session>(initialState));

export let persist;

@Injectable({ providedIn: 'root' })
export class SessionRepository {
  isLoggedIn$ = store.pipe(
    select((state) => state.access_token),
    map((token) => Boolean(token))
  );

  session$ = store.pipe(select((state) => state));

  constructor() {
    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'session',
      storage: strategy,
    });
  }

  isLoggedIn(): boolean {
    return Boolean(store.getValue().access_token);
  }

  session(): Session {
    return store.getValue();
  }

  update(session: Session) {
    store.update(() => session);
  }

  resetSession() {
    store.update(() => initialState);
  }
}
