<app-modal [autoSize]="true">
  <h2 class="u-width--100 u-text-align--left">{{ 'ENDPOINT.TITLE' | translate }}</h2>
  <form
    [formGroup]="configurationForm"
    (submit)="confirmConfiguration()"
    class="u-width--100 u-margin-top--md u-margin-bottom--md">
    <app-field-input [label]="'ENDPOINT.DOMAIN' | translate" formControlName="endpoint"></app-field-input>
  </form>

  <div class="c-modal__footer">
    <app-button variant="outline" (click)="setDefaultValues()">{{ 'GENERAL.DEFAULT' | translate }}</app-button>
    <div></div>
    <app-button color="danger" variant="outline" (click)="confirmConfiguration()"
      >{{ 'GENERAL.CANCEL' | translate }}</app-button
    >
    <app-button color="primary" (click)="confirmConfiguration()">{{ 'GENERAL.SAVE' | translate }}</app-button>
  </div>
</app-modal>
