import { Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../../environments/environment';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  public debug(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.debug(payload);
    }
  }

  public info(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.info(payload);
    }
  }

  public warning(payload: any): void {
    if (this.isEnabled()) {
      this.rollbar.warning(payload);
    }
  }

  public error(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      this.rollbar.error(payload?.message || payload);
    }
  }

  public critical(payload: any): void {
    if (this.shouldDiscardPayload(payload)) {
      return;
    }

    if (this.isEnabled()) {
      this.rollbar.error(payload?.message || payload);
    }
  }

  private shouldDiscardPayload(payload: any) {
    // ignore auth and offline errors
    if ([0, 400, 401].indexOf(payload?.original?.status) > -1) {
      return true;
    }

    return false;
  }

  private isEnabled() {
    return environment.log.enabled;
  }
}
