import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ToastModule } from './core/toasts/toast.module';

// ====================================
// SERVICES
// ====================================

import { ExampleCoreService } from './core/services/example-service/example-core.service';
import { GlobalErrorHandlerService } from './core/services/global-error-handler/global-error-handler.service';
import { CustomTranslationsLoaderService } from './core/services/custom-translations-loader-service/custom-translations-loader.service';

// ====================================
// INTERCEPTORS
// ====================================
import { httpInterceptorProviders } from './core/interceptors';

import { MetadataRepository } from './core/state/metadata/metadata.repository';
import { rollbarFactory, RollbarService } from './core/services/rollbar/rollbar.service';

/** @ignore */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationsLoaderService,
        deps: [HttpClient, MetadataRepository],
      },
    }),
  ],
  providers: [
    ExampleCoreService,
    httpInterceptorProviders,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
