import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { IntegrationRepository } from '../integration/integration.repository';
import { StoreManagementService } from '../store-management/store-management.service';
import { SyncRepository } from '../sync/sync.repository';
import { Session } from './session.interface';
import { SessionRepository } from './session.repository';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private sessionRepository: SessionRepository,
    private storeManagementService: StoreManagementService,
    private syncRepository: SyncRepository,
    private integrationRepository: IntegrationRepository
  ) {}

  postLogin(body) {
    body = { ...body, client_id: environment.api.clientId };

    return this.http.post<Session>(`${environment.api.endpoint}/lab/auth/login`, body).pipe(
      tap((session) => {
        this.sessionRepository.update(session);
      })
    );
  }

  postLogout() {
    this.syncRepository.setAirplaneModeEnabled(false);
    return this.http.post(`${environment.api.endpoint}/staff/logout`, null).pipe(
      finalize(() => {
        this.appLogout();
      })
    );
  }

  resetSessionStore() {
    this.storeManagementService.resetRepositories();
    this.sessionRepository.resetSession();
    this.integrationRepository.resetSession();
  }

  appLogout() {
    this.resetSessionStore();
    this.router.navigate(['/login']);
  }
}
