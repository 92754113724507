import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

import packageJson from '../../package.json';
import { TranslationsService } from './core/services/translations-service/translations.service';
import { environment } from '../environments/environment';
import { StoreManagementService } from './core/state/store-management/store-management.service';
import { ToastService } from './core/toasts/services/toast-service/toast.service';
import { catchError, forkJoin } from 'rxjs';
import { MetadataService } from './core/state/metadata/metadata.service';
import { SyncService } from './core/state/sync/sync.service';
import { ElectronService } from './core/services/electron/electron.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  host: {
    '(window:beforeunload)': 'resetRepositoriesOnReload()',
  },
})
export class AppComponent {
  isNotProd: boolean = false;

  constructor(
    private translationsService: TranslationsService,
    private platform: Platform,
    private storeManagementService: StoreManagementService,
    private toastService: ToastService,
    private metadataService: MetadataService,
    private syncService: SyncService,
    private electronService: ElectronService,
    private title: Title
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.electronService.init();
      this.title.setTitle(`LabApp (v${packageJson.version})`);
      this.translationsService.init();

      this.isNotProd = !environment.production;

      this.loadMetadata();

      // Using a init method to make sure the Service is initialized when the app starts
      this.syncService.init();
    });
  }

  resetRepositoriesOnReload() {
    if (this.isNotProd) {
      return;
    }

    this.storeManagementService.resetRepositories();
  }

  private loadMetadata() {
    const languages = this.metadataService.getLanguages();
    const phoneCodes = this.metadataService.getPhoneCountryCodes();
    const timezones = this.metadataService.getTimezones();
    const ethnicities = this.metadataService.getEthnicities();

    forkJoin([languages, phoneCodes, timezones, ethnicities])
      .pipe(
        catchError((err) => {
          this.toastService.error(err.message);
          throw err;
        })
      )
      .subscribe();
  }
}
